.ZVXwDIlb.u7s5Rghi {
  color: #242938;
}
.ZVXwDIlb.u7s5Rghi:hover {
  text-decoration: underline;
}

.__6KabV3Yl.ant-modal {
  top: 0;
  max-width: 760px;
  padding-bottom: 0;
  margin: 0 auto;
}
.__6KabV3Yl .ant-modal-content {
  border-radius: 0;
  height: 100vh;
}
.__6KabV3Yl .ant-modal-content .ant-modal-body {
  background-color: inherit;
}
.__6KabV3Yl.__1QUYqwFP .dol-editor {
  font-family: Inter, sans-serif;
}
.mBVEU1XB.__1QUYqwFP .dol-editor {
  font-family: Inter, sans-serif;
}
.mBVEU1XB .__9IGV7l3h {
  display: inline-flex;
  align-items: center;
  color: #d14242;
  cursor: pointer;
  transition: transform 0.2s;
}
.mBVEU1XB .__9IGV7l3h:hover {
  transform: scale(1.1);
}

